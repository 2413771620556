@import "../../../theme.scss";
.Indent-Request-panel-content {
  position: relative;
  padding: 1px 0;
  ul {
    list-style-type: none;
  }

  .list-column {
    position: relative;
    padding-top: 5px;
    float: left;
    width: 100%;
    .list-style-two {
      position: relative;
      font-size: 14px;
      font-family: "Montserrat", sans-serif;
    
      li {
        position: relative;
        line-height: 30px;
        border: none;
        // border-bottom: 1px solid #f0f0f0;
      }
      li:last-child {
        border: none;
      }
      li .ttl,
      li .dtl {
        position: relative;
        float: left;
        display: block;
      }
      li .ttl {
      
        width: 50%;
      }
      li .dtl {
        color: #232527;
        font-weight: 600;
        text-transform: uppercase;
        // width: 100%;
        .user-column{
          
          list-style-type: none;
          background-color: $gray-200;
          border: 1px solid $gray-400;
          box-shadow: $box-shadow;
          border-radius: 5px;
          height: 35px;
          width: 150px;
        
        li .img{
          padding: 0px;
          float: left;
          img{
            width: 40px;
            height: 35px;
            // border: 1px solid #232527;
            border-radius: 4px 0 0 4px ;
          }
        
        }
        li .userttl{
          padding: 1px 0px;
          font-size: 11px;
          float: right;
          display: block;
        }
        }
      }
    }
  }
}
.ApprovedBox{
  box-shadow: $box-shadow;
  border-radius: 5px;
  padding: 2px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.info-column{
	margin:0 5px;
}
.details-header{
	position:relative;
	margin-bottom:5px;
 h4{
	font-size:24px;
	font-weight:600;
	margin:-5px 0px 5px;
	line-height:1.4em;
	color:#232527;
}
}
.item-price{
	font-size:44px;
	font-weight:400;
	letter-spacing:1px;
	// padding-bottom:14px;
  border-bottom:1px solid #f2f2f2;
    h2{
        color:$primary;
    }
}
