@import "../../theme.scss";

.Master-dashboard {
  // position:static;
  //   width: 75%;

  //   text-align: center;
  //   background-color: rgba($gray-400, 0.2);
  //   margin-left: 45px;
  //   border-radius: $border-radius;
  //   box-shadow: $box-shadow;

  .Master-dashboard-menu {
    padding: 10px 10px;
    // vertical-align: middle;
    .Master-dashboard-item {
      height: 80px;
      //   margin-bottom: 1rem;
      //   vertical-align: middle;
      padding: 1.24rem 1rem;
      color: $gray-900;
      background-color: $white;
      box-shadow: $box-shadow;
      border-radius: 10px;
      position: relative;
      //   font-size: 14px;
      transition: all 0.4s;
      font-family: $font-family-secondary;
      //   font-weight: 500;
      .title-box {
        font-size: 15px;
        padding: 0.45rem 0;
        // text-align: center;
        text-transform: uppercase;
        // text-align: center;
        // background-color: rgba($dark, 0.2);
      }

      .view-box {
        // position: relative;
        // padding: 0.5rem;
        // height: 40px;
        // width: 100%;
        // font-size: 18px;
        // border: 1px solid black;

        // background-color: $white;
        // text-align: center;
        a {
          padding: 0.1rem 0.2rem;
          width: 45px;
          //   background-color: rgba($primary, 0.2);
          border-radius: $border-radius;
          position: relative;
          text-align: center;

          i {
            //   display: block;
            //   min-width: 1.5rem;
            //   padding-bottom: 0.125em;
            //   background-color:;
            //   box-shadow: $box-shadow;
            font-size: 1.5rem;
            line-height: 2rem;
            text-align: center;
            color: $gray-900;
            transition: all 0.4s;
            //   opacity: 0.75;
          }
          &:hover {
            color: $white;
            background-color: $primary;
            border-radius: $border-radius;
            // padding: 1px 20px;
            i {
              color: $white;
            }
          }
        }
      }
      &:hover {
        color: $primary !important;
        transform: scale(1.05);
        border: 0.012rem solid $primary;
        // padding: 14px 20px;
        //   background-color: rgba($gray-900, 0.2);
        // box-shadow: 0 0 2px 0 $dark;
        // border-radius: $border-radius;
        i {
          color: $primary;
        }
      }
    }

    // ul {
    //   li {
    //     padding: 0.25rem 0.5rem;

    //     a {
    //       display: block;
    //       height: 75px;
    //       padding: 1rem 0.5rem;
    //       color: $gray-900;
    //       background-color: rgba($primary, 0.2);
    //       border-radius: $border-radius;
    //       position: relative;
    //       font-size: 13.3px;
    //       transition: all 0.4s;
    //       font-family: $font-family-secondary;
    //       font-weight: 500;

    //       i {
    //         display: block;
    //         min-width: 1.5rem;
    //         padding-bottom: 0.125em;
    //         font-size: 1.5rem;
    //         line-height: 1.40625rem;

    //         color: $gray-900;
    //         transition: all 0.4s;
    //         opacity: 0.75;
    //       }

    //       &:hover {
    //         color: $dark;
    //         background-color: rgba($dark, 0.2);
    //         border-radius: $border-radius;

    //         i {
    //           color: $dark;
    //         }
    //       }
    //     }

    //     .badge {
    //       margin-top: 4px;
    //     }
    //   }
    // }
  }
}
