@import "../../../theme.scss";
.Header {
  display: block;
  .title {
    display: inline-flex;
    // position: relative;;
    align-items: center;
  }
  .title-1 {
    font-size: 18px;
  }
  .device-pill-green {
    background: #08a742;
    color: #fff;
  }
  .device-pill {
    height: 24px;
    min-width: 8px;
    max-width: 100%;
    padding: 0 8px;
    box-sizing: border-box;
    background: #e5e5e5;
    color: #404346;
    border-radius: 16px;
    display: inline-flex;
    flex: 0 0 auto;
    align-items: center;
    cursor: default;
    vertical-align: top;

    .device-pill-label {
      box-sizing: border-box;
      padding: 0 4px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
.device-body{
 box-shadow: $box-shadow;
    background-color:$gray-100;
}
