@import "../../theme.scss";
.bg_color {
  background-color: $gray-300;

  .Material_detiles {
    text-align: left;
    padding-top: 5px;
    .orderlist_but {
      width: 100px;
    }
    .items_title {
      color: $gray-600;
      font-size: 14px;
      .item_title_Detiles {
        color: $gray-900;
      }
    }
  }
  .Material_body {
    // border-bottom: 1px solid $gray-600;
    .more-button{
      width: 4%;
      margin-left: 5px;
    }
    .body_title {
      color: $gray-600;
    }
  }

  .Material-panel-content {
    position: relative;
    padding: 1px 0;
    ul {
      list-style-type: none;
    }

    .list-column {
      position: relative;
      padding-top: 5px;
      float: left;
      width: 100%;
      .list-style-seven {
        position: relative;
        font-size: 14px;
        font-family: "Montserrat", sans-serif;
        li {
          position: relative;
          line-height: 22px;
          border-bottom: 1px solid #f0f0f0;
        }
        li:last-child {
          border: none;
        }
        li .ttl,
        li .dtl {
          position: relative;
          float: left;
          display: block;
         
        }
        li .ttl {
          
          width: 25%;
        }
        li .dtl {
          color: #232527;
          font-weight: 600;
          text-transform: uppercase;
          // width: 100%;
          
        }
      }
    }
  }
  .user-content-box{
    padding: 5px 0;
    ul {
      list-style-type: none;
    }
    li .ttl,
    li .dtl {
      position: relative;
      float: right;
      display: block;
     
    }
    li .ttl {
      text-transform: uppercase;
    }
    
  }
  .user-column{
    list-style-type: none;
    background-color: $gray-200;
    border: 1px solid $gray-400;
    box-shadow: $box-shadow;
    border-radius: 5px;
 
  li .img{
    padding: 0px;
    float: left;
    img{
      width: 50px;
      height: 35px;
      // border: 1px solid #232527;
      border-radius: 4px 0 0 4px ;
    }

  }
  li .ttl{
    padding: 5px;
    float: right;
    display: block;
  }
}

}

