//
// ecommerce.scss
//

@import "../../theme.scss";

// -------------
.inner-box {
  position: relative;
  display: flex;
  background-color: #ffffff;
  border: 1px solid #f2f2f2;
  .image {
    position: relative;
    text-align: center;
    object-fit: cover;
    border-bottom: 1px solid #f2f2f2;
    img {
        height: 150px;
      }
    .price {
      position: absolute;
      right: 0px;
      bottom: 0px;
      color: #232527;
      font-size: 18px;
      font-weight: 400;
      padding: 5px 15px 5px 10px;
      background-color: #f7f7f7;
      font-family: "Montserrat", sans-serif;
      transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
    }
    .price:before {
      position: absolute;
      content: "";
      left: -26px;
      top: 0px;
      bottom: 0px;
      border-bottom: 37px solid #f7f7f7;
      border-left: 26px solid transparent;
      transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
    }

    
  }
  h3 {
    position: relative;
    font-size: 14px;
    font-weight: 700;
    padding: 10px;
    padding-bottom: 5px;
    margin-bottom: 0px;
    border-bottom: 1px solid #c0bcbc;
    text-transform: uppercase;
    a {
      color: #232628;
      transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
    }
    a:hover {
      color: $primary;
    }
  }

  .vehicle-list-column {
    position: relative;
    padding-left: 2px;
    padding-bottom: 2px;
    // padding-top: 5px;
    float: left;
    width: 100%;
    // border-bottom: 1px solid #000000;
    ul {
      list-style-type: none;
    }
    .vehicle-list-style {
      position: relative;
      font-size: 14px;
      //   font-family: "Montserrat", sans-serif;

      li {
        position: relative;
        line-height: 20px;
        border: none;
        // border-bottom: 1px solid #f0f0f0;
      }
      li:last-child {
        border: none;
      }
      li .ttl,
      li .dtl {
        position: relative;
        float: left;
        display: block;
      }
      li .ttl {
        width: 50%;
      }
      li .dtl {
        color: #000000;
        font-weight: 600;
        text-transform: uppercase;
        // width: 100%;
      }
    }
  }
  .lower-box {
    position: relative;
    padding: 10px 5px;
    border-top: 1px solid #b8b6b6;
    // z-index: -1;
    // box-shadow: $box-shadow;
    // background-color: #f7f7f7;
    .lower-box-border {
      border-right: 1px solid #b8b6b6;
      padding-right: 0px;
      .car-info {
        position: relative;
        color: #848484;
        padding: 5px 2px;
        font-size: 12px;
        display: inline-block;
      }
    }
    .last-child {
      border-right: none;
    }
  }
}
.inner-box:hover {
  border-radius: 5px;
  box-shadow: 1px solid #000000;
  border: 1px solid $primary;
  zoom: 1.005;
  .image {
    .price {
      color: #ffffff;
      background-color: $primary;
    }
    .price:before {
      color: #ffffff;
      border-bottom-color: $primary;
    }
  }
}
.react-tooltip-lite {
    background: #333;
    color: white;
    border-radius: 5px;
  }
  
  .react-tooltip-lite-arrow {
    border-color: #333;
  }