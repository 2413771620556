@import "../../../theme.scss";

.adminactive {
  color: $primary !important;
  background-color: rgba($primary, 0.2);
  border-radius: $border-radius;
  .mm-adminactive {
    > .has-arrow {
      &:after {
        transform: rotate(-180deg);
      }
    }
  }

  .has-arrow {
    &:after {
      content: "\F0140";
      font-family: "Material Design Icons";
      display: block;
      float: right;
      transition: transform 0.2s;
      font-size: 1rem;
    }
  }
  &:hover {
    color: $primary !important;
    background-color: rgba($primary, 0.2);
    border-radius: $border-radius;
  }
  i {
    color: $primary !important;
  }
}
> i {
  color: $danger !important;
}

.menu-position {
  background: $sidebar-bg;
  bottom: 0;
  margin-top: 0;
  position: fixed;
  top: $header-height;
  box-shadow: $box-shadow;

  width: 160px;
  // border: 1px solid #000;

  margin-left: 2.5px;
  // position: relative;
}

.vertical-admin-menu {
  // position:static;
  // width: 80%;
  // height: 100%;
  text-align: center;
  background-color: $white;
  // margin-left: 45px;
  border-radius: $border-radius;
  box-shadow: $box-shadow;

  .admin-sidebar-menu {
    padding: 10px 0 30px 0;

    .mm-active {
      > .has-arrow {
        &:after {
          transform: rotate(-180deg);
        }
      }
    }

    .has-arrow {
      &:after {
        content: "\F0140";
        font-family: "Material Design Icons";
        display: block;
        float: right;
        transition: transform 0.2s;
        font-size: 1rem;
      }
    }

    ul {
      li {
        padding: 0.25rem 0.5rem;
        a {
          display: block;
          padding: 1rem 0.5rem;
          color: $gray-900;
          height: 80px;
          position: relative;
          font-size: 13.3px;
          transition: all 0.4s;
          font-family: $font-family-secondary;
          font-weight: 500;

          i {
            display: block;
            min-width: 1.5rem;
            padding-bottom: 0.125em;
            font-size: 1.7rem;
            line-height: 1.40625rem;

            color: $gray-900;
            transition: all 0.4s;
            opacity: 0.75;
          }

          &:hover {
            color: $dark;
            background-color: rgba($dark, 0.2);
            border-radius: $border-radius;

            i {
              color: $dark;
            }
          }
        }

        .badge {
          margin-top: 4px;
        }
      }
    }
  }
}
