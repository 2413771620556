//
// p-image.scss
//

.p-image {
  position: relative;
  display: inline-flex;
  .img-icon{
    display: none;
    position: absolute;
    margin-top:80px;
    right: 18px;
    font-size: 2.2em;
    i {
     
      font-size: .52em;
      background-color: #ffffff;
      border-radius: 55px;
      border: 5px solid #ffffff;
    }
  }  
  .file-upload{
    display: none;
  }

}
.p-image:hover{
  img{
    border: 2px solid #999;
  }
  .img-icon{
    display:block;
  
  }
}
