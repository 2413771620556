.react-switch {
    vertical-align: middle;
    margin-left: 4px;
  }
.redio-switch{
    vertical-align: middle;
    margin: 4px;
}
.disabled-link {
  cursor: not-allowed;
  pointer-events: none;
  color:inherit;
  text-decoration: none;
}
.enabled-link{
  cursor: pointer;
}
